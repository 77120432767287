var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"preview"}},[(_vm.previewContents || _vm.contents)?_c('div',{staticClass:"preview"},[_c('div',{staticClass:"document-container",class:{'screen-hide': _vm.getQueryParams && _vm.getQueryParams.download , 'print-hide': _vm.getQueryParams && _vm.getQueryParams.download}},[(_vm.showWatermark)?_c('div',{staticClass:"screen-hide",attrs:{"id":"watermark"}},[_vm._v(" DRAFT ")]):_vm._e(),_c('footer',{staticClass:"screen-hide"},[_c('span',{staticClass:"footer-left float-left"},[_vm._v(" "+_vm._s(_vm.partner1Name)+" Initials ______ ")]),_c('span',{staticClass:"footer-right float-right"},[_vm._v(" "+_vm._s(_vm.partner2Name)+" Initials ______ ")])]),_c('h1',{staticClass:"print-hide screen-hide"},[_vm._v(_vm._s(_vm.filename))]),(_vm.previewContents)?_c('div',{staticClass:"main-content",domProps:{"innerHTML":_vm._s(_vm.previewContents)}}):(_vm.contents)?_c('div',{staticClass:"main-content",domProps:{"innerHTML":_vm._s(_vm.contents)}}):_vm._e(),_c('div',{staticClass:"screen-hide certificates-section"},[(
					_vm.agreement.partner1
						.includeCertificateOfIndependantLegalAdvice
				)?_c('certificate-of-independant-legal-advice',{attrs:{"agreement":_vm.agreement,"advice-partner":_vm.agreement.partner1,"other-partner":_vm.agreement.partner2,"relationship-status":_vm.agreement.relationship.status}}):_vm._e(),(
					_vm.agreement.partner2
						.includeCertificateOfIndependantLegalAdvice
				)?_c('certificate-of-independant-legal-advice',{attrs:{"agreement":_vm.agreement,"advice-partner":_vm.agreement.partner2,"other-partner":_vm.agreement.partner1,"relationship-status":_vm.agreement.relationship.status}}):_vm._e()],1)]),_c('div',{staticClass:"landscape",class:{
			'screen-hide': !_vm.getQueryParams || !_vm.getQueryParams.download || (_vm.getQueryParams && _vm.getQueryParams.download != 'property-schedule')
			}},[_c('div',{staticClass:"property-schedule-content"},[_c('h4',[_vm._v("Property Schedule")]),_c('property-schedule',{staticStyle:{"width":"100%"},attrs:{"agreementData":_vm.agreement}})],1)])]):_c('div',{staticClass:"\n\t\t\ttext-center\n\t\t\tw-100\n\t\t\td-flex\n\t\t\tjustify-content-center\n\t\t\talign-items-center\n\t\t",staticStyle:{"height":"500px"}},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"Loading..."}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }