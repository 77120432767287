<script>
import { mapState } from 'vuex';

import filters from '@/lib/agreement/filters';
import generateDocument from '@/lib/agreement/generate';

import CertificateOfIndependantLegalAdvice from './CertificateOfIndependantLegalAdvice.vue';
import PropertySchedule from '../PropertySchedule.vue';

export default {
	components: { CertificateOfIndependantLegalAdvice, PropertySchedule },
	computed: {
		...mapState(['agreement']),
		partner1Name() {
			return filters.firstLastName(this.agreement.partner1);
		},
		partner2Name() {
			return filters.firstLastName(this.agreement.partner2);
		},
		getQueryParams(){
			return this.$route.query;
		}
	},
	props: {
		filename: { default: () => '', type: String },
		previewContents: { default: () => null, type: String },
		showWatermark: { default: () => false, type: Boolean },
	},
	asyncComputed: {
		contents: {
			watch: ['agreement'],
			async get() {
				this.error = false;
				if (!this.agreement.id) return;
				try {
					return this.generateDocument();
				} catch (error) {
					if (process.env.NODE_ENV === 'development') {
						console.error(error);
					}
					this.error = true;
				}
			},
		},
	},
	methods: {
		generateDocument() {
			return generateDocument({
				agreementData: this.agreement,
			});
		},
	},
};
</script>

<template>
	<div id="preview">
		<div v-if="previewContents || contents" class="preview">
			<div class="document-container" :class="{'screen-hide': getQueryParams && getQueryParams.download , 'print-hide': getQueryParams && getQueryParams.download}">
			<div v-if="showWatermark" id="watermark" class="screen-hide">
				DRAFT
			</div>
			<footer class="screen-hide">
				<span class="footer-left float-left">
					{{ partner1Name }} Initials ______
				</span>

				<span class="footer-right float-right">
					{{ partner2Name }} Initials ______
				</span>
			</footer>
			<h1 class="print-hide screen-hide">{{ filename }}</h1>
			<div v-if="previewContents" v-html="previewContents" class="main-content"/>
			<div v-else-if="contents" v-html="contents" class="main-content"/>
			<div class="screen-hide certificates-section">
				<certificate-of-independant-legal-advice
					v-if="
						agreement.partner1
							.includeCertificateOfIndependantLegalAdvice
					"
					:agreement="agreement"
					:advice-partner="agreement.partner1"
					:other-partner="agreement.partner2"
					:relationship-status="agreement.relationship.status"
				/>
				<certificate-of-independant-legal-advice
					v-if="
						agreement.partner2
							.includeCertificateOfIndependantLegalAdvice
					"
					:agreement="agreement"
					:advice-partner="agreement.partner2"
					:other-partner="agreement.partner1"
					:relationship-status="agreement.relationship.status"
				/>
			</div>
			</div>

			<div 
				class="landscape"
				:class="{
				'screen-hide': !getQueryParams || !getQueryParams.download || (getQueryParams && getQueryParams.download != 'property-schedule')
				}"
			>
			<div class="property-schedule-content">
				<h4>Property Schedule</h4>
				<property-schedule :agreementData="agreement" style="width: 100%"/>
			</div>
		</div>
		</div>
		<div
			v-else
			class="
				text-center
				w-100
				d-flex
				justify-content-center
				align-items-center
			"
			style="height: 500px"
		>
			<b-spinner
				style="width: 3rem; height: 3rem"
				variant="primary"
				label="Loading..."
			/>
		</div>
	</div>
</template>

<style>
@import '../../assets/css/printstyles.css';

#downloadLink {
	display: none;
}

.missing {
	background: yellow;
}

.preview {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.main-content {
  margin: 0;
  padding: 0;
  width: 100%;
}

.property-schedule-content {
  margin: 0;
  padding: 0;
}

@media print {
  .preview > div {
    margin: 0;
    padding: 0;
  }

  .document-container {
    break-inside: avoid;
  }
}

</style>
